<template>
    <div class="conbox flexCloumn" style="padding: 20px">
        <div id="anchor_school" style="width: 100%; height: 1px"></div>
        <div class="conbox" style="width: 100%">

            <div style="width: calc(50% - 20px); background-color: #fff; border-radius: 4px">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="" />
                    <div class="boxtitle">
                        各学校样本数统计
                        <el-tag class="ml10 pointer" size="small" plain @click="exportChart('chart_sch_1', '各学校样本数统计')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                </div>
                <div id="chart_sch_1" style="width: 95%; height: 690px"></div>
            </div>

            <div class="ml20" style="width: 50%; background-color: #fff; border-radius: 4px">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="" />
                    <div class="boxtitle">
                        各学校样本就业率统计图
                        <el-tag class="ml10 pointer" size="small" plain
                            @click="exportChart('chart_sch_2', '各学校样本就业率统计图')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                </div>
                <div id="chart_sch_2" style="width: 95%; height: 690px"></div>
            </div>
        </div>
        <div class="conbox mt20" style="width: 100%; flex-wrap: wrap">
            <div style="width: 100%; background-color: #fff; border-radius: 4px">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="" />
                    <div class="boxtitle">
                        各学校样本对口就业率统计图
                        <el-tag class="ml10 pointer" size="small" plain
                            @click="exportChart('chart_sch_3', '各学校样本对口就业率统计图')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                </div>
                <div id="chart_sch_3" style="height: 500px; width: 95%"></div>
            </div>
            <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="" />
                    <div class="boxtitle">
                        各学校样本毕业生满意度统计图
                        <el-tag class="ml10 pointer" size="small" plain
                            @click="exportChart('chart_sch_4', '各学校样本毕业生满意度统计图')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                </div>
                <div id="chart_sch_4" style="height: 500px; width: 95%"></div>
            </div>
            <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="" />
                    <div class="boxtitle">
                        各学校样本合同签订情况
                        <el-tag class="ml10 pointer" size="small" plain
                            @click="exportChart('chart_sch_5', '各学校样本合同签订情况')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                </div>
                <div id="chart_sch_5" style="height: 500px; width: 95%"></div>
            </div>
            <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
                <div class="boxinner flexStart" style="">
                    <img src="/img/home_title_icon.png" alt="" />
                    <div class="boxtitle">
                        各学校样本薪资对比图
                        <el-tag class="ml10 pointer" size="small" plain
                            @click="exportChart('chart_sch_6', '各学校样本薪资对比图')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                </div>
                <div id="chart_sch_6" style="height: 500px; width: 95%"></div>
            </div>
        </div>

        <div id="anchor_major" style="width: 100%; height: 1px"></div>
        <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
            <div class="boxinner flexStart" style="">
                <img src="/img/home_title_icon.png" alt="" />
                <div class="boxtitle">
                    各专业大类样本就业率统计表
                    <el-tag class="ml10 pointer" size="small" plain
                        @click="exportChart('chart_major_1', '各专业大类样本就业率统计表')">
                        <i class="el-icon-download"></i>
                        下载图表</el-tag>
                </div>
            </div>
            <div id="chart_major_1" style="width: 95%; height: 400px"></div>
        </div>
        <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
            <div class="boxinner flexStart" style="">
                <img src="/img/home_title_icon.png" alt="" />
                <div class="boxtitle">
                    各专业大类样本对口率统计表
                    <el-tag class="ml10 pointer" size="small" plain
                        @click="exportChart('chart_major_2', '各专业大类样本对口率统计表')">
                        <i class="el-icon-download"></i>
                        下载图表</el-tag>
                </div>
            </div>
            <div id="chart_major_2" style="width: 95%; height: 400px"></div>
        </div>
        <div class="mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
            <div class="boxinner flexStart" style="">
                <img src="/img/home_title_icon.png" alt="" />
                <div class="boxtitle">
                    各专业大类样本薪资平均值对比图
                    <el-tag class="ml10 pointer" size="small" plain
                        @click="exportChart('chart_major_3', '各专业大类样本薪资平均值对比图')">
                        <i class="el-icon-download"></i>
                        下载图表</el-tag>
                </div>
            </div>
            <div id="chart_major_3" style="height: 500px; width: 95%"></div>
        </div>

        <div id="anchor_city" style="width: 100%; height: 1px"></div>
        <div class="conbox flexCloumn mt20" style="width: 100%; background-color: #fff; border-radius: 4px">
            <div class="boxinner flexStart" style="width: 100%">
                <img src="/img/home_title_icon.png" alt="" />
                <div class="boxtitle">
                    南京市中等职业学校样本就业情况分布图
                    <el-tag class="ml10 pointer" size="small" plain
                        @click="exportTable('myPieCharts', '南京市中等职业学校就业情况分布图')">
                        <i class="el-icon-download"></i>
                        下载图表</el-tag>
                </div>
            </div>
            <div id="myPieCharts"
                style="width: 100%; display: flex; flex-flow: row wrap; justify-content: space-around; margin-top: 30px">
                <div class="chartPie">
                    <div id="yjChart1" style="width: 95%; height: 100%"></div>
                </div>
                <div class="chartPie">
                    <div id="yjChart2" style="width: 95%; height: 100%"></div>
                </div>
                <div class="chartPie">
                    <div id="yjChart21" style="width: 95%; height: 100%"></div>
                </div>
                <div class="chartPie">
                    <div id="yjChart3" style="width: 95%; height: 100%"></div>
                </div>
                <!-- <div class="chartPie">
                    <div id="yjChart4" style="width: 95%; height: 100%"></div>
                </div> -->
                <div class="chartPie">
                    <div id="yjChart5" style="width: 95%; height: 100%"></div>
                </div>
                <!-- <div class="chartPie" style="width: 25%">
                    <div id="yjChart6" style="width: 95%; height: 100%"></div>
                </div> -->
                <div class="chartPie" style="width: 25%">
                    <div id="yjChart7" style="width: 95%; height: 100%"></div>
                </div>
                <!-- <div class="chartPie"></div> -->
                <div class="chartPie" style="width: 25%">
                    <div id="yjChart8" style="width: 95%; height: 100%"></div>
                </div>
                <div class="chartPie" style="width: 25%">
                    <div id="yjChart20" style="width: 95%; height: 100%"></div>
                </div>
                <!-- <div class="chartPie"></div> -->
            </div>
        </div>
        <div class="mt20" style="padding: 0 50px 50px 50px; width: 100%; background-color: #fff; border-radius: 4px">
            <div class="boxinner flexStart" style="width: calc(100% + 50px); margin-left: -50px">
                <img src="/img/home_title_icon.png" alt="" />
                <div class="boxtitle flexBetween flex_1">
                    <div>
                        南京市中等职业学校专业大类样本毕业生人数统计表
                        <el-tag class="ml10 pointer" size="small" plain
                            @click="exportTable('myexporttable', '南京市中等职业学校专业大类样本毕业生人数统计表')">
                            <i class="el-icon-download"></i>
                            下载图表</el-tag>
                    </div>
                    <!-- <i class="el-icon-download" style="cursor: pointer" @click="exportTable"></i> -->
                </div>
            </div>

            <table id="myexporttable" class="dttable" v-if="MajorTongji.length > 0">
                <tr>
                    <td style="width: 4.96%; color: #333; font-weight: 700">学校名称</td>
                    <td style="width: 4.75%; color: #333; font-weight: 700"
                        v-for="(mj, i) in MajorTongji[0].major_types" :key="i">
                        <span :class="mj.type_name == '合计' ? 'color_hj' : ''">{{ mj.type_name }}</span>
                    </td>
                </tr>
                <tr v-for="(sch, i) in MajorTongji" :key="'sch_' + i">
                    <td :class="sch.platform == '合计' ? 'color_hj' : ''" style="color: #333; font-weight: 700">{{
                        sch.platform }}</td>
                    <td style="color: #333" v-for="(mj, mi) in sch.major_types" :key="'major_' + mi">
                        <span
                            :class="sch.name == '合计' || mi == sch.major_types.length - 1 || i == MajorTongji.length - 1 ? 'color_hj' : ''">{{
                            mj.ybs_num || "" }}</span>
                    </td>
                </tr>
            </table>
        </div>

        <div style="height: 20px; color: #fff"></div>
    </div>
</template>

<script>
import echartMixins from "../../js/resizeMixins";
let colors = require("./color.js");
import html2canvas from "html2canvas";

export default {
    props: ["tPmid"],

    data() {
        return {
            Tongji: {
                data: [],
                colors: ["#e062ae", "#fb7293", "#e690d1", "#32c5e9", "#96bfff"],
                unit: "企业数",
                showValue: true,
            },
            MajorTongji: [],
            pieData: {},
        };
    },
    mixins: [echartMixins],

    mounted() {
        this.getStatistic()
    },
    methods: {
        exportChart(chartName, downloadName) {
            // let myChart = this.$echarts.getInstanceByDom(this.$refs.myChart);
            let myChart = this.$echarts.getInstanceByDom(document.getElementById(chartName));
            // 获取画布图表地址信息
            const imgUrl = myChart.getDataURL({
                type: "jpeg",
                pixelRatio: 1,
                backgroundColor: "#fff",
                excludeComponents: [
                    //保存图表时忽略的工具组件,默认忽略工具栏
                    "toolbox",
                ],
            });
            // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
            if (window.navigator.msSaveOrOpenBlob) {
                // 截取base64的数据内容（去掉前面的描述信息，类似这样的一段：data:image/png;base64,）并解码为2进制数据
                let bstr = atob(imgUrl.split(",")[1]);
                // 获取解码后的二进制数据的长度，用于后面创建二进制数据容器
                let n = bstr.length;
                // 创建一个Uint8Array类型的数组以存放二进制数据
                let u8arr = new Uint8Array(n);
                // 将二进制数据存入Uint8Array类型的数组中
                while (n--) {
                    u8arr[n] = bstr.charCodeAt(n);
                }
                // 创建blob对象
                let blob = new Blob([u8arr]);
                // 调用浏览器的方法，调起IE的下载流程
                window.navigator.msSaveOrOpenBlob(blob, downloadName + ".jpg");
            } else {
                //类似chrome浏览器创建一个a标签并使用a标签的href属性下载
                let tempA = document.createElement("a");
                tempA.download = downloadName + ".jpg";
                tempA.href = imgUrl;
                document.body.appendChild(tempA);
                tempA.click();
                tempA.remove();

                // var url = myChart.getConnectedDataURL({
                //   pixelRatio: 5, //导出的图片分辨率比率,默认是1
                //   backgroundColor: '#fff', //图表背景色
                //   excludeComponents: [ //保存图表时忽略的工具组件,默认忽略工具栏
                //     'toolbox'
                //   ],
                //   type: 'png' //图片类型支持png和jpeg
                // });
            }
        },

        exportTable(chartName, downloadName) {
            html2canvas(document.getElementById(chartName), {
                backgroundColor: "white",
                useCORS: true, //支持图片跨域
                scale: 1, //设置放大的倍数
                height: document.getElementById(chartName).scrollHeight,
                windowHeight: document.getElementById(chartName).scrollHeight,
            }).then((canvas) => {
                // 生成图片导出
                const a = document.createElement("a");
                a.href = canvas.toDataURL("image/png");
                a.download = downloadName;
                a.click();
            });
        },
        getStatistic() {
            this.$http.post("/api/get_sch_survey_count").then((res) => {
                this.DataList = res.data;
                $("#bycount").text(res.data.bys_num);
                $("#ybcount").text(res.data.ybs_num);
                $("#jycount").text(res.data.jy_num);
                $("#dycount").text(res.data.dy_num);
                let jylarr = JSON.parse(JSON.stringify(res.data.ybjytj));
                jylarr[0].platform = "南京市样本平均就业率"
                let jylarr_1 = this._lo.slice(jylarr, -(jylarr.length - 1));
                jylarr_1 = jylarr_1.sort((a, b) => { return b.jy_percent - a.jy_percent; });
                jylarr_1.unshift(jylarr[0])
                this.initSch_1(res.data.ybrstj, jylarr_1)
                this.initSch_2(res.data.ybrstj, jylarr_1)

                let arr_1 = JSON.parse(JSON.stringify(res.data.dkjytj));
                let arr_2 = JSON.parse(JSON.stringify(res.data.mydtj));
                let arr_3 = JSON.parse(JSON.stringify(res.data.htqdtj));
                let arr_4 = JSON.parse(JSON.stringify(res.data.xztj));
                arr_1[0].platform = "南京市样本平均对口就业率"
                arr_2[0].platform = "南京市样本平均就业满意度"
                arr_3[0].platform = "南京市样本平均合同签订率"
                arr_4[0].platform = "南京市样本平均薪资"
                let arr_11 = this._lo.slice(arr_1, -(arr_1.length - 1));
                let arr_22 = this._lo.slice(arr_2, -(arr_2.length - 1));
                let arr_33 = this._lo.slice(arr_3, -(arr_3.length - 1));
                let arr_44 = this._lo.slice(arr_4, -(arr_4.length - 1));
                arr_11 = arr_11.sort((a, b) => { return b.dkjy_percent - a.dkjy_percent; });
                arr_22 = arr_22.sort((a, b) => { return b.myjy_percent - a.myjy_percent; });
                arr_33 = arr_33.sort((a, b) => { return b.htqd_percent - a.htqd_percent; });
                arr_44 = arr_44.sort((a, b) => { return b.avg_salary - a.avg_salary; });
                arr_11.unshift(arr_1[0])
                arr_22.unshift(arr_2[0])
                arr_33.unshift(arr_3[0])
                arr_44.unshift(arr_4[0])

                this.initEverSchools(arr_11, arr_22, arr_33, arr_44)
            });


            this.$http.post("/api/get_major_type_survey_count").then((res) => {
                let arr1 = JSON.parse(JSON.stringify(res.data.jytj));
                let arr2 = JSON.parse(JSON.stringify(res.data.dkjytj));
                let arr3 = JSON.parse(JSON.stringify(res.data.xztj));
                this._lo.remove(arr1, (item) => item.jy_percent === 0);
                this._lo.remove(arr2, (item) => item.dkjy_percent === 0);
                this._lo.remove(arr3, (item) => item.avg_salary === 0);
                arr1 = arr1.sort((a, b) => {
                    return b.jy_percent - a.jy_percent;
                });
                arr2 = arr2.sort((a, b) => {
                    return b.dkjy_percent - a.dkjy_percent;
                });
                arr3 = arr3.sort((a, b) => {
                    return b.avg_salary - a.avg_salary;
                });
                this.initMajors(arr1, arr2, arr3)
            });
            this.$http.post("/api/get_survey_pie_count").then((res) => {
                this.pieData = res.data
                this.initPies()
            });
            this.$http.post("/api/get_sch_majortype_survey_count").then((res) => {
                this.MajorTongji = res.data;
            });

        },

        // initSchColumn
        initSch_1(data, data2) {
            let resData = JSON.parse(JSON.stringify(data));
            resData = resData.reverse();

            let reverseColor = JSON.parse(JSON.stringify(colors));
            reverseColor = reverseColor.reverse();
            let xData = resData.map((e) => {
                return e.platform;
            });
            let ydata = resData.map((e) => {
                return e.ybs_num;
            })
            let option = {
                title: {
                    text: "",
                    left: "center",
                    textStyle: {
                        color: "white",
                    },
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {
                            title: "下载图片", //鼠标滑过之后文案
                            name: "我的图表", //图表名称
                        },
                    },
                },
                grid: { left: 190, top: 0, },
                tooltip: {
                    show: false, trigger: "axis", title: { show: false, },
                    formatter: function (params) {
                        let index = params[0].dataIndex;
                        let info = resData[index];
                        let formaterstr = "", inner0 = "", inner1 = "", inner2 = "";
                        inner0 = ` <span>毕业生总数:${info.bycount}</span>
                                <br/>`;
                        inner1 = ` <span>就业人数:${info.jycount}</span>
                                <br/>`;
                        inner2 = ` <span>待业人数:${info.dycount}</span>
                                <br/>`;
                        formaterstr = inner0 + inner1 + inner2;
                        // 这里去掉了 title，只保留了 series 名称和数据的描述
                        return formaterstr;
                    },
                },
                xAxis: {
                    type: "value", data: xData,
                    axisPointer: { type: "shadow", },
                    axisLabel: {
                        // color: 'white'
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    type: "category", data: xData,
                    axisLabel: {
                        // color: 'white'
                        textStyle: {
                            color: "#000", // 设置 x 轴文字颜色
                            fontWeight: "bold",
                        },
                        formatter: function (value, index) {
                            // 根据 value 或 index 自定义标签样式
                            if (value == "南京市样本平均就业率") {
                                return "{a|南京市样本平均}" + "{b|就业率}";
                            }
                            return value;
                        },
                        rich: {
                            a: {
                                color: "#f56c6c",
                                // fontSize: 16,
                                fontWeight: "bold",
                            },
                            b: {
                                color: "#f56c6c",
                                // fontSize: 16,
                                fontWeight: "bold",
                            },
                        },
                    },
                    splitLine: { show: false },
                },
                series: [
                    {
                        name: "样本人数",
                        data: ydata,
                        type: "bar",
                        barMaxWidth: 20,
                        showBackground: false,
                        itemStyle: {
                            color: function (params) {
                                return reverseColor[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
                        },
                        label: {
                            show: true,
                            position: "inside",
                            formatter: function (params) {
                                //标签内容
                                return params.value + "人";
                            },
                        },
                        itemStyle: {
                            color: function (params) {
                                if (params.name == "南京市样本平均就业率") {
                                    return "#f56c6c";
                                }
                                return reverseColor[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
                        },
                    },
                ],
            };
            option.toolbox.feature.saveAsImage.name = "各学校样本数统计";
            this.$echarts.init(document.getElementById("chart_sch_1")).setOption(option);
        },
        initSch_2(data, data2) {
            let resData = JSON.parse(JSON.stringify(data2));
            resData = resData.reverse();
            let reverseColor = JSON.parse(JSON.stringify(colors));
            reverseColor = reverseColor.reverse();
            let xData = resData.map((e) => {
                return e.platform;
            });
            let ydata = resData.map((e) => {
                return e.jy_percent;
            })
          
         
            let option = {
                title: {
                    text: "",
                    left: "center",
                    textStyle: {
                        color: "white",
                    },
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {
                            title: "下载图片", //鼠标滑过之后文案
                            name: "我的图表", //图表名称
                        },
                    },
                },
                grid: { left: 190, top: 0, },
                tooltip: {
                    show: true, trigger: "axis", title: { show: false, },
                    formatter: function (params) {
                        let index = params[0].dataIndex;
                        let info = resData[index];
                        let formaterstr = "",
                            inner0 = "",
                            inner1 = "",
                            inner2 = "";
                        inner0 = ` <span>样本总数:${info.ybs_num}</span>
                                <br/>`;
                        inner1 = ` <span>样本就业人数:${info.jy_num}</span>
                                <br/>`;
                        inner2 = ` <span>样本待业人数:${info.dy_num}</span>
                                <br/>`;
                        formaterstr = inner0 + inner1 + inner2;
                        // 这里去掉了 title，只保留了 series 名称和数据的描述
                        return formaterstr;
                    },
                },
                xAxis: {
                    type: "value", data: xData,
                    axisPointer: { type: "shadow", },
                    axisLabel: {
                        // color: 'white'
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    type: "category", data: xData,
                    axisLabel: {
                        // color: 'white'
                        textStyle: {
                            color: "#000", // 设置 x 轴文字颜色
                            fontWeight: "bold",
                        },
                        formatter: function (value, index) {
                            // 根据 value 或 index 自定义标签样式
                            if (value == "南京市样本平均就业率") {
                                return "{a|南京市样本平均}" + "{b|就业率}";
                            }
                            return value;
                        },
                        rich: {
                            a: {
                                color: "#f56c6c",
                                // fontSize: 16,
                                fontWeight: "bold",
                            },
                            b: {
                                color: "#f56c6c",
                                // fontSize: 16,
                                fontWeight: "bold",
                            },
                        },
                    },
                    splitLine: { show: false },
                },
                series: [
                    {
                        name: "样本人数",
                        data: ydata,
                        type: "bar",
                        barMaxWidth: 20,
                        showBackground: false,
                        itemStyle: {
                            color: function (params) {
                                return reverseColor[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
                        },
                        label: {
                            show: true,
                            position: "inside",
                            formatter: function (params) {
                                //标签内容
                                return params.value + "%";
                            }
                        },
                        itemStyle: {
                            color: function (params) {
                                if (params.name == "南京市样本平均就业率") {
                                    return "#f56c6c";
                                }
                                return reverseColor[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
                        },
                    },
                ],
            };
            option.toolbox.feature.saveAsImage.name = "各学校样本就业率统计图";
            this.$echarts.init(document.getElementById("chart_sch_2")).setOption(option);
        },
        initEverSchools(arr_1, arr_2, arr_3, arr_4) {
            let dataAxis = this._lo.map(arr_1, "platform");
            let data = this._lo.map(arr_1, "jy_percent");

            let option = {
                title: {
                    text: "各学校就业率统计图",
                    left: "center",
                    textStyle: {
                        color: "white",
                    },
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {
                            title: "下载图片", //鼠标滑过之后文案
                            name: "我的图表", //图表名称
                        },
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                },
                grid: {
                    top: "5%",
                    left: "5%",
                    right: "5%",
                    bottom: "30%",
                },
                xAxis: {
                    type: "category",
                    data: dataAxis,
                    axisPointer: {
                        type: "shadow",
                    },
                    itemStyle: {
                        color: function (params) {
                            return colors[params.dataIndex];
                        },
                        borderRadius: [10, 10, 0, 0],
                        shadowBlur: 1, // 设置阴影模糊度
                        shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
                    },
                    axisLabel: {
                        textStyle: {
                            color: "#000", // 设置 x 轴文字颜色
                            fontWeight: "bold",
                        },
                        rotate: 45, // 旋转角度为45度
                        formatter: function (value, index) {
                            // 根据 value 或 index 自定义标签样式
                            if (value == "南京市样本平均对口就业率") {
                                return "{a|南京市样本平均}" + "{b|对口就业率}";
                            }
                            if (value == "南京市样本平均就业满意度") {
                                return "{a|南京市样本平均}" + "{b|就业满意度}";
                            }
                            if (value == "南京市样本平均合同签订率") {
                                return "{a|南京市样本平均}" + "{b|合同签订率}";
                            }
                            if (value == "南京市样本平均薪资") {
                                return "{a|南京市样本平均}" + "{b|薪资}";
                            }
                            return value;
                        },
                        rich: {
                            a: {
                                color: "#f56c6c",
                                // fontSize: 16,
                                fontWeight: "bold",
                            },
                            b: {
                                color: "#f56c6c",
                                // fontSize: 16,
                                fontWeight: "bold",
                            },
                        },
                    },
                },
                yAxis: {
                    type: "value",
                    name: "百分比",
                    splitLine: { show: false },
                    // axisLabel: { color: '#fff' },
                },
                color: ["#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
                series: [
                    {
                        type: "bar",
                        barMaxWidth: 40,

                        data: data,
                        label: {
                            show: true,
                            position: "inside",
                            // formatter: function (params) { //标签内容
                            //   return params.value + '%'
                            // },
                        },
                        itemStyle: {
                            color: function (params) {
                                if (params.name == "南京市样本平均对口就业率" || params.name == "南京市样本平均就业满意度" || params.name == "南京市样本平均合同签订率" || params.name == "南京市样本平均薪资") {
                                    return "#f56c6c";
                                }
                            },
                        },
                    },
                ],
            };
            let myChart = null;

            dataAxis = this._lo.map(arr_1, "platform");
            data = this._lo.map(arr_1, "dkjy_percent");
            option.title.text = "";
            option.grid.top = 50;
            option.tooltip.formatter = function (params) {
                let formaterstr = "",
                    inner0 = "";
                inner0 = `<span>${params[0].value}%</span>`;
                formaterstr = inner0;
                // 这里去掉了 title，只保留了 series 名称和数据的描述
                return formaterstr;
            };
            option.xAxis.data = dataAxis;
            option.series[0].data = data;
            option.toolbox.feature.saveAsImage.name = "各学校样本对口就业率统计图";
            myChart = this.$echarts.init(document.getElementById("chart_sch_3"));
            myChart.setOption(option);

            dataAxis = this._lo.map(arr_2, "platform");
            option.xAxis.data = dataAxis;
            data = this._lo.map(arr_2, "myjy_percent");
            option.title.text = "";
            option.series[0].data = data;
            option.toolbox.feature.saveAsImage.name = "各学校样本毕业生满意度统计图";
            myChart = this.$echarts.init(document.getElementById("chart_sch_4"));
            myChart.setOption(option);

            dataAxis = this._lo.map(arr_3, "platform");
            option.xAxis.data = dataAxis;
            data = this._lo.map(arr_3, "htqd_percent");
            option.title.text = "";
            option.series[0].data = data;
            option.toolbox.feature.saveAsImage.name = "各学校样本合同签订情况";
            myChart = this.$echarts.init(document.getElementById("chart_sch_5"));
            myChart.setOption(option);

            dataAxis = this._lo.map(arr_4, "platform");
            option.xAxis.data = dataAxis;
            data = this._lo.map(arr_4, "avg_salary");
            option.title.text = "";
            option.tooltip.formatter = function (params) {
                let formaterstr = "",
                    inner0 = "";
                inner0 = `<span>${params[0].value}元</span>`;
                formaterstr = inner0;
                // 这里去掉了 title，只保留了 series 名称和数据的描述
                return formaterstr;
            };
            option.yAxis.name = "薪资(元)";
            option.yAxis.interval = null;
            option.series[0].data = data;
            option.toolbox.feature.saveAsImage.name = "各学校样本薪资对比图";
            myChart = this.$echarts.init(document.getElementById("chart_sch_6"));
            myChart.setOption(option);
        },
        initMajors(arr_1, arr_2, arr_3) {
            let dataAxis = this._lo.map(arr_1, "type_name");
            let data = this._lo.map(arr_1, "jy_percent");
            let option = {
                title: {
                    text: "",
                    left: "center",
                    textStyle: {
                        color: "white",
                    },
                },
                toolbox: {
                    show: false,
                    feature: {
                        saveAsImage: {
                            title: "下载图片", //鼠标滑过之后文案
                            name: "我的图表", //图表名称
                        },
                    },
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        crossStyle: {
                            color: "#fff",
                        },
                    },
                    formatter: function (params) {
                        let formaterstr = "",
                            inner0 = "";
                        inner0 = `<span>${params[0].value}%</span>`;
                        formaterstr = inner0;
                        // 这里去掉了 title，只保留了 series 名称和数据的描述
                        return formaterstr;
                    },
                },
                grid: {
                    top: 30,
                    left: "5%",
                    right: "5%",
                    bottom: "25%",
                },

                xAxis: [
                    {
                        type: "category",
                        data: dataAxis,
                        axisPointer: {
                            type: "shadow",
                        },
                        itemStyle: {
                            color: function (params) {
                                return colors[params.dataIndex];
                            },
                            borderRadius: [10, 10, 0, 0],
                            shadowBlur: 1, // 设置阴影模糊度
                            shadowColor: "rgba(255, 255, 255, 0.5)", // 设置阴影颜色
                        },
                        axisLabel: {
                            rotate: 45, // 旋转角度为45度
                            // color: "#ffffff"
                            textStyle: {
                                color: "#000", // 设置 x 轴文字颜色
                                fontWeight: "bold",
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                        name: "百分比",
                        splitLine: { show: false },
                        axisLabel: {
                            // color: 'white'
                        },
                    },
                ],
                color: ["#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
                series: [
                    {
                        name: "就业率",
                        type: "bar",
                        barMaxWidth: "40",
                        data: data,
                        label: {
                            show: true,
                            position: "inside",
                        },
                    },
                ],
            };

            option.toolbox.feature.saveAsImage.name = "各专业大类样本就业率统计表";
            this.$echarts.init(document.getElementById("chart_major_1")).setOption(option);

            dataAxis = this._lo.map(arr_2, "type_name");
            data = this._lo.map(arr_2, "dkjy_percent");
            option.xAxis.data = dataAxis;
            option.title.text = "";
            option.series[0].name = "对口率";
            option.series[0].data = data;
            option.toolbox.feature.saveAsImage.name = "各专业大类样本对口率统计表";
            this.$echarts.init(document.getElementById("chart_major_2")).setOption(option);

            dataAxis = this._lo.map(arr_3, "type_name");
            data = this._lo.map(arr_3, "avg_salary");
            option.title.text = "";
            option.tooltip.formatter = function (params) {
                let formaterstr = "",
                    inner0 = "";
                inner0 = `<span>${params[0].value}元</span>`;
                formaterstr = inner0;
                // 这里去掉了 title，只保留了 series 名称和数据的描述
                return formaterstr;
            };
            option.xAxis.data = dataAxis;
            option.yAxis.name = "薪资(元)";
            option.yAxis.interval = null;
            option.series[0].data = data;
            option.toolbox.feature.saveAsImage.name = "各专业大类样本薪资平均值对比图";
            this.$echarts.init(document.getElementById("chart_major_3")).setOption(option);

        },
        initPies() {
            let option = {
                title: {
                    text: "",
                    left: "center",
                    textStyle: {
                        // color: 'white'
                    },
                },
                toolbox: {
                    show: true,
                    feature: {
                        saveAsImage: {
                            title: "下载图片", //鼠标滑过之后文案
                            name: "我的图表", //图表名称
                        },
                    },
                },
                tooltip: {
                    trigger: "item",
                },

                series: [
                    {
                        name: "Access From",
                        type: "pie",
                        radius: ["30%", "50%"],
                        avoidLabelOverlap: true,
                        itemStyle: {
                            borderRadius: 10,
                            // borderColor: '#fff',
                            borderWidth: 2,
                        },
                        label: {
                            normal: {
                                formatter: "{b}{c}人({d}%)",
                                textStyle: {
                                    fontWeight: "normal",
                                    fontSize: 12,
                                    color: "#000",
                                },
                            },
                        },
                        data: [],
                        color: ["#78A3E0", "#6055BB", "#88C6BB", "#E9A65D", "#78A3E0", "#6055BB", "#2469f3", "#cccc99", "#ff6666", "#cccc00", "#996699", "#333333"],
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: "rgba(0, 0, 0, 0.5)",
                            },
                        },
                    },
                ],
            };

            //就业率
            let percent = 0;
            let xdata = [
                { value: this.pieData.jy_percent, name: "就业" },
                { value: (100 - this.pieData.jy_percent).toFixed(2), name: "待业" },
            ];
            option.series[0].name = "就业率";
            option.series[0].data = xdata;
            option.series[0].label.normal.formatter = "{b}{c}%";
            option.title.text = "就业率";
            option.toolbox.feature.saveAsImage.name = "就业率";
            this.$echarts.init(document.getElementById("yjChart1")).setOption(option);

            // 对口就业率
            xdata = [
                { value: this.pieData.dkjy_percent, name: "对口" },
                { value: (100 - this.pieData.dkjy_percent).toFixed(2), name: "不对口" },
            ];
            option.series[0].name = "对口就业率";
            option.series[0].data = xdata;
            option.title.text = "对口就业率";
            option.toolbox.feature.saveAsImage.name = "对口就业率";
            this.$echarts.init(document.getElementById("yjChart2")).setOption(option);

            // 企业类型
            let enterType = this.pieData.enter_type_values;
            xdata = enterType.map((e) => {
                return { value: e.value, name: e.title };
            });
            option.title.text = "企业类型";
            option.series[0].name = "企业类型";
            option.series[0].data = xdata;
            option.toolbox.feature.saveAsImage.name = "企业类型";
            this.$echarts.init(document.getElementById("yjChart21")).setOption(option);

            //就业去向分组
            xdata = this.pieData.jyqx_values
                .filter((e) => !!e.title)
                .map((e) => {
                    return { value: e.value, name: e.title };
                });
            option.title.text = "就业去向分组";
            option.series[0].name = "就业去向";
            option.series[0].data = xdata;
            option.series[0].label.normal.formatter = "{b}{c}人({d}%)";
            option.toolbox.feature.saveAsImage.name = "就业去向";
            this.$echarts.init(document.getElementById("yjChart3")).setOption(option);

            //就业产业分组
            // xdata = this.pieData.chanye.map((e) => {
            //     if (e.title && e.title != "") {
            //         return { value: e.value, name: e.title };
            //     }
            // });

            // option.title.text = "就业产业分组";
            // option.series[0].name = "就业产业";
            // option.series[0].data = xdata;
            // option.toolbox.feature.saveAsImage.name = "就业产业";
            // this.$echarts.init(document.getElementById("yjChart4")).setOption(option);

            //就业地域
            xdata = this.pieData.jydy_values.map((e) => {
                if (e.title && e.title != "") {
                    return { value: e.value, name: e.title };
                }
            });
            option.title.text = "就业地域分组";
            option.series[0].name = "就业地域";
            option.series[0].data = xdata;
            option.toolbox.feature.saveAsImage.name = "就业地域";
            this.$echarts.init(document.getElementById("yjChart5")).setOption(option);

            //就业地点
            // xdata = this.pieData.didian.map((e) => {
            //     if (e.didian && e.didian != "") {
            //         return { value: e.n, name: e.didian };
            //     }
            // });
            // option.title.text = "就业地点分组";
            // option.series[0].name = "就业地点";
            // option.series[0].data = xdata;
            // option.toolbox.feature.saveAsImage.name = "就业地点";
            // this.$echarts.init(document.getElementById("yjChart6")).setOption(option);

            //就业渠道
            xdata = this.pieData.jyqd_values.map((e) => {
                if (e.title && e.title != "") {
                    return { value: e.value, name: e.title };
                }
            });
            option.title.text = "就业渠道分组";
            option.series[0].name = "就业渠道";
            option.series[0].data = xdata;
            option.toolbox.feature.saveAsImage.name = "就业渠道";
            this.$echarts.init(document.getElementById("yjChart7")).setOption(option);

            //满意度
            xdata = this.pieData.myd_values.map((e) => {
                return { value: e.value, name: e.title };
            });
            option.title.text = "满意度情况";
            option.series[0].name = "满意度情况";
            option.series[0].data = xdata;
            option.toolbox.feature.saveAsImage.name = "满意度情况";
            this.$echarts.init(document.getElementById("yjChart8")).setOption(option);
            //保险情况
            xdata = this.pieData.bx_values.map((e) => {
                return { value: e.value, name: e.title };
            });
            option.title.text = "保险情况";
            option.series[0].name = "保险情况";
            option.series[0].data = xdata;
            option.toolbox.feature.saveAsImage.name = "保险情况";
            this.$echarts.init(document.getElementById("yjChart20")).setOption(option);
        },



    },
    beforeDestroy: function () { },
};
</script>

<style scope lang="less" type="text/less">
.conbox {
    display: flex;
    /*color: #fff;
    padding: 0 50px;*/
}

.boxinner {
    padding: 30px 12px;
}

.boxtitle {
    height: 30px;
    padding-left: 10px;
    /*margin-bottom: 10px;
    background-image: linear-gradient(90deg, rgba(0, 160, 233, .3176470588235294), rgba(0, 160, 233, 0));*/
    line-height: 30px;
    font-size: 20px;
    font-weight: 700;
}

.title2 {
    background-image: linear-gradient(270deg, rgba(0, 160, 233, 0.3176470588235294), rgba(0, 160, 233, 0));
}

.chart {
    width: 50%;
    height: 300px;
}

.chartPie {
    /*width: 33.3%;*/
    width: 25%;
    height: 300px;
}

.color_hj {
    color: #6055bb !important;
    font-weight: 700;
}
</style>
